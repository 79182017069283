import { css } from '@emotion/react'
import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { HStack } from 'components/Stack'
import { Caption } from 'components/Typography'
import NextImage from 'next/legacy/image'
import { logError, matchMap } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { BREAKPOINTS } from 'utils/styled'
import {
  SLogoWrapper,
  SMentionItem,
  SMentionItemLink,
} from './LandingPageTextImageMediaMentions.styled'

export function LandingPageTextImageMediaMentions(props: {
  mentions: ContentfulMediaMentions
  responsiveItemCounts?: [number, number, number]
  title?: string
  className?: string
}) {
  const { isDesktop, isXLarge } = useWindowSize()

  const screenLevel = !isDesktop ? 'mobile' : !isXLarge ? 'tablet' : 'desktop'

  const mentionCount = props.responsiveItemCounts
    ? matchMap(screenLevel, {
        mobile: props.responsiveItemCounts?.[0] ?? Infinity,
        tablet: props.responsiveItemCounts?.[1] ?? Infinity,
        desktop: props.responsiveItemCounts?.[2] ?? Infinity,
      })
    : Infinity

  const mentions = props.mentions?.items.slice(0, mentionCount)

  return (
    <div
      css={css`
        @media ${BREAKPOINTS.MD.max} {
          margin-left: auto;
          margin-right: auto;
        }
      `}
      className={props.className}
    >
      {!!props.title && (
        <Caption
          className="desktop-only"
          css={css`
            margin-bottom: 1rem;
          `}
        >
          {props.title}
        </Caption>
      )}
      <HStack
        gap={['1.5rem', '1.5rem', '2.5rem']}
        css={css`
          @media ${BREAKPOINTS.MD.max} {
            justify-content: center;
            flex-wrap: wrap;
          }
        `}
      >
        {mentions?.map((mention) => {
          if (!mention?.logo?.url) {
            logError('Empty logo url')
            return null
          } else {
            return mention.url ? (
              <SMentionItemLink
                key={mention.logo.url}
                href={mention.url}
                target="_blank"
                rel="noreferrer"
              >
                <SLogoWrapper>
                  <NextImage
                    src={mention.logo.url}
                    alt={mention.logo.title ?? 'media'}
                    height={40}
                    width={72}
                  />
                </SLogoWrapper>
              </SMentionItemLink>
            ) : (
              <SMentionItem key={mention.logo.url}>
                <SLogoWrapper>
                  <NextImage
                    src={mention.logo.url}
                    alt={mention.logo.title ?? 'media'}
                    width={72}
                    height={40}
                  />
                </SLogoWrapper>
              </SMentionItem>
            )
          }
        })}
      </HStack>
    </div>
  )
}
