import { Button } from 'components/button/Button'
import { ButtonProps } from 'components/button/types'
import {
  ThemedTextImageSection,
  ThemedTextImageSectionProps,
} from 'components/content/themedTextImageSection/ThemedTextImageSection'
import { Icon, IconName } from 'components/icon'
import { ContentfulImage } from 'components/Image'
import { Text } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { matchMap, record } from 'utils/general'
import { Namespaces } from 'utils/i18n/types'
import { Subset } from 'utils/types'

type Props = {
  section: Subset<
    keyof Namespaces['group_life']['group_life'],
    'section_coverage' | 'section_provider' | 'section_online' | 'section_estate_plan'
  >
} & Partial<ThemedTextImageSectionProps>

export const GroupLifeLPSection = ({ section, ...extraProps }: Props) => {
  const { t } = useTranslation('group_life')
  const images = useContentfulImages()

  const ctaHref = '/goodtrust-life/signup'

  const buttonIcon = matchMap(
    section,
    record<typeof section, IconName>({
      section_coverage: 'LifeInsurance',
      section_provider: 'Plus',
      section_online: 'LifeInsurance',
      section_estate_plan: 'Will',
    })
  )

  return (
    <ThemedTextImageSection
      title={t(`group_life.${section}.title`)}
      image={<ContentfulImage {...images[`group_life_${section}`]} />}
      {...extraProps}
    >
      <Text>
        <Trans t={t} i18nKey={`group_life.${section}.description`} />
      </Text>
      <Button {...defaultButtonProps} leftIcon={<Icon name={buttonIcon} />} href={ctaHref}>
        {t(`group_life.${section}.cta`)}
      </Button>
    </ThemedTextImageSection>
  )
}

const defaultButtonProps: ButtonProps = {
  type: 'button',
  variant: 'text',
  size: 'large',
  style: { alignSelf: 'start' },
  tone: 'dark',
}
