import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BREAKPOINTS } from 'utils/styled'

export const SHeaderLandingPage = styled.div<{ showBorder: boolean; noSpacingBelow?: boolean }>`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  ${({ showBorder }) =>
    showBorder
      ? css`
          box-shadow: 0px 1px 0px rgba(0, 125, 188, 0.12);
        `
      : null};
  ${({ noSpacingBelow }) => (noSpacingBelow ? null : `margin-bottom: 2.25rem;`)}
  transition: box-shadow 300ms;

  padding: 1.5rem var(--p-spacing);

  @media ${BREAKPOINTS.LG.max} {
    padding: 1.5rem 1rem;
    gap: 3rem;
  }

  @media ${BREAKPOINTS.MD.max} {
    height: var(--h-landing-page-header);
    padding: 0 1rem;
  }
`
export const SDivider = styled.div`
  height: 1rem;
  width: 1px;
  background-color: #e7e8e9;
`

export const SLogoWrapper = styled.a`
  svg {
    width: 9.2rem;
  }
`

export const SRightSideWrapper = styled.div`
  display: flex;
  gap: 4rem;

  @media ${BREAKPOINTS.MD.max} {
    flex-direction: row-reverse;
    gap: 0;
  }
`
