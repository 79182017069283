import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useSubscriptionMap } from 'api/goodtrust/subscription'
import { Button } from 'components/button/Button'
import { TwoLineButtonContent } from 'components/button/TwoLineButtonContent'
import { Card } from 'components/card/Card'
import { TopHighlight } from 'components/card/TopHighlight'
import { DescribedPerk } from 'components/groupLifeSection/DescribedPerk'
import { IncludesEstatePlan } from 'components/groupLifeSection/IncludesEstatePlan'
import { HStack, Stack } from 'components/Stack'
import { useCancelDowngrade } from 'logic/subscription/downgrade/useCancelDowngrade'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { useSubscriptionCardButton } from 'logic/subscription/button/useSubscriptionCardButton'
import { Caption, Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { events } from 'utils/analytics'
import { setPlanCart, usePlanCart } from 'utils/cart'
import { formatPriceInCents, withDollarSign } from 'utils/format'
import { matchMap, matchPartialMap } from 'utils/general'
import { useBestPlanDiscount } from 'utils/promo/useBestPlanDiscount'
import { pxToRemUnit } from 'utils/styled'
import { Subset } from 'utils/types'

export function PricingSection() {
  return (
    <Stack orientation={['vertical', 'horizontal']} space={['1.5rem', '1rem']}>
      <PricingCard plan="GROUP_LIFE_10K" />
      <PricingCard plan="GROUP_LIFE_25K" highlight />
      <PricingCard plan="GROUP_LIFE_50K" />
    </Stack>
  )
}

const SDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--c-blue100);
`

const PricingCard: FC<
  React.PropsWithChildren<{
    plan: Subset<PlanType, 'GROUP_LIFE_10K' | 'GROUP_LIFE_25K' | 'GROUP_LIFE_50K'>
    highlight?: boolean
  }>
> = (props) => {
  const { t } = useTranslation('group_life')
  const router = useRouter()
  const subItem = useSubscriptionMap().data?.json?.subscriptions?.[props.plan]
  const planSpec = planSpecs[props.plan]

  const discount = useBestPlanDiscount(props.plan)
  const cart = usePlanCart()
  const button = useSubscriptionCardButton(props.plan, router.query.next as string | undefined)
  const { cancelDowngrade, isCancelingDowngrade } = useCancelDowngrade()

  return (
    <Card
      css={css`
        display: flex;
        flex-direction: column;
        padding: 2rem;
        flex: 1;
        gap: ${pxToRemUnit(20)};
      `}
    >
      {!!props.highlight && <TopHighlight>{t('group_life.pricing.highlight_text')}</TopHighlight>}

      {planSpec.groupLifeCoverageInCents != null && subItem?.DEFAULT?.price != null && (
        <Prices coverage={planSpec.groupLifeCoverageInCents} monthly={subItem?.DEFAULT.price} />
      )}

      <SDivider />

      {planSpec.groupLifeCoverageInCents != null && (
        <DescribedPerk
          title={t('group_life.pricing.perk_includes_coverage', {
            coverage: withDollarSign(formatPriceInCents(planSpec.groupLifeCoverageInCents)),
          })}
          description={t(
            `group_life.pricing.${matchMap(props.plan, {
              GROUP_LIFE_10K: 'cheap_plan',
              GROUP_LIFE_25K: 'medium_plan',
              GROUP_LIFE_50K: 'expensive_plan',
            } as const)}`
          )}
        />
      )}

      <IncludesEstatePlan />

      <div css={{ flexGrow: 1 }} />

      <Button
        variant={props.plan === 'GROUP_LIFE_25K' ? 'primary' : 'secondary'}
        disabled={button.disabled}
        size="large"
        type="button"
        href={button.href}
        css={{ textAlign: 'center' }}
        loading={isCancelingDowngrade}
        onClick={async () => {
          if (button.behavior === 'renew') {
            await cancelDowngrade('GROUP_LIFE')
            return
          }
          const isSigningUp = !!cart?.isSigningUp
          events.gl_plan_selected.fire({ plan: props.plan })
          setPlanCart({
            type: props.plan,
            firstSelectedType: props.plan,
            isSigningUp,
            groupLifeForm: cart?.groupLifeForm,
            promo: discount?.promo.code
              ? {
                  promoCode: discount.promo.code,
                  offer: discount.discount,
                  partner: discount.promo.partner,
                }
              : undefined,
          })
        }}
      >
        {matchPartialMap(button.behavior, {
          upgrade: t('group_life.pricing.button'),
          downgrade: t('group_life.pricing.downgrade_button'),
        }) ??
          (typeof button.content === 'string' ? (
            button.content
          ) : (
            <TwoLineButtonContent line1={button.content[0]} line2={button.content[1]} />
          ))}
      </Button>
    </Card>
  )
}

const Prices = (props: { coverage: number; monthly: number }) => {
  const { t } = useTranslation('group_life')

  return (
    <HStack space={'2rem'}>
      <Stack>
        <Text
          variant="bodyLargeBold"
          css={css`
            font-size: 1.5rem;
            color: black;
          `}
        >
          ${formatPriceInCents(props.coverage)}
        </Text>
        <Caption>{t('group_life.pricing.coverage')}</Caption>
      </Stack>
      <Stack>
        <Text
          variant="bodyLargeBold"
          css={css`
            font-size: 1.5rem;
            color: black;
          `}
        >
          ${formatPriceInCents(props.monthly)}
        </Text>
        <Caption>{t('group_life.pricing.monthly')}</Caption>
      </Stack>
    </HStack>
  )
}
