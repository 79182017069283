import { forwardRef } from 'react'
import { SDot, SWrapper } from './Carousel.styled'
import AliceCarousel from 'react-alice-carousel'
import CarouselType, { Props } from 'react-alice-carousel'

export const Carousel = forwardRef<CarouselType, Props>((props, ref) => {
  return (
    <SWrapper>
      <AliceCarousel
        renderDotsItem={(dot) => <SDot isActive={dot.isActive} />}
        disableButtonsControls
        ref={ref}
        {...props}
      />
    </SWrapper>
  )
})
Carousel.displayName = 'Carousel'
