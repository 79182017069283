import { patchUserTips, useUserTips } from 'api/goodtrust/user'
import { handleAndToastError } from 'components/Toast'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { unwrapResponse } from 'utils/fetcher'
import { useRunOnceReady } from 'utils/hooks'

export function useGroupLifeEnabled(opts?: { enableOnReady: boolean }) {
  const tipSwr = useUserTips()
  const { isLogged } = useAuth()

  // the naming is a bit confusing
  // but hide_grouplife === true actually means the exact opposite :matej-serious:
  // so this hook shields the user from this irregularity
  const isEnabled = tipSwr.data?.json?.show_grouplife

  useRunOnceReady(
    opts?.enableOnReady === true && isLogged === true && isEnabled === false,
    async () => {
      await patchUserTips({
        show_grouplife: true,
      })
        .then(unwrapResponse)
        .catch(handleAndToastError)
    }
  )

  return isEnabled
}
