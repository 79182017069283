import { ContentfulFAQ, getContentfulFAQ } from 'api/contentful/getContentfulFAQ'
import {
  ContentfulMediaMentions,
  getContentfulMediaMentions,
} from 'api/contentful/getContentfulMediaMention'
import { Anchor } from 'components/Anchor'
import { Block } from 'components/Block'
import { HeroWithPerks } from 'components/cornerstone/heroWithPerks/HeroWithPerks'
import { FaqTwoColumns } from 'components/faqTwoColumn/FaqTwoColumn'
import { Footer } from 'components/footer/Footer'
import { GroupLifeLPSection } from 'components/groupLifeSection/GroupLifeLPSection'
import { GroupLifeNavigation } from 'components/groupLifeSection/GroupLifeNavigation'
import { PricingLPSection } from 'components/groupLifeSection/PricingLPSection'
import { IconName } from 'components/icon'
import { Layout } from 'components/layout/Layout'
import { PageMeta } from 'components/PageMeta'
import { PageTitle } from 'components/PageTitle'
import { Signup, ToneContext } from 'components/signup/Signup'
import { Stack } from 'components/Stack'
import { Statistics } from 'components/statistics/Statistics'
import { useGroupLifeEnabled } from 'logic/subscription/plan/groupLife/useGroupLifeEnabled'
import { isGroupLifeAvailable } from 'logic/subscription/plan/groupLife/isGroupLifeAvailable'
import { Caption } from 'components/Typography'
import AmalgamatedAgency from 'images/extra-svg/AmalgamatedAgency.svg'
import { GetStaticProps } from 'next'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import { mutateAnalyticsData } from 'utils/analytics'
import { REVALIDATE_SECS } from 'utils/constants'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { getContentfulImages } from 'utils/contentful/getPageImages'
import { numberRange } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { getIntlProps } from 'utils/i18n/getIntlProps'
import { mapKeysToTrans } from 'utils/i18n/mapKeysToTrans'
import { NumberRange } from 'utils/types'

type Props = {
  faq: ContentfulFAQ
  mediaMentions: ContentfulMediaMentions
}

const statisticIcons: Record<NumberRange<3>, IconName> = {
  '1': 'ShieldCheck',
  '2': 'Flash',
  '3': 'MoneyBag',
}

export default function Page(props: Props) {
  const { t } = useTranslation('group_life')
  useGroupLifeEnabled({ enableOnReady: true })
  const images = useContentfulImages()

  useEffect(() => {
    mutateAnalyticsData({ type: 'group_life_insurance' })
  }, [])

  const { isDesktop } = useWindowSize({ isDesktop: true })

  const heroPerks = mapKeysToTrans(
    t,
    numberRange(5).map((i) => `group_life.hero.perk_${i}` as const)
  )

  const signupPerks = mapKeysToTrans(
    t,
    numberRange(5).map((i) => `group_life.section_signup.perk_${i}` as const)
  )

  return (
    <Layout header={<GroupLifeNavigation />} footer={<Footer variant="full" />}>
      <PageTitle title={t('group_life.meta.title')} />
      <PageMeta
        description={t('group_life.meta.description')}
        image="/images/preview/group-life.png"
      />

      <Stack gap={['3rem', '7.5rem']}>
        <HeroWithPerks
          image={isDesktop ? images.group_life_hero : images.group_life_hero_mobile}
          mentions={props.mediaMentions}
          buttonText={t('group_life.hero.buttonText')}
          mentionsCaption={t('group_life.hero.mentionCaption')}
          title={t('group_life.hero.title')}
          bgColor="gray"
          buttonHref={'/goodtrust-life/signup'}
          icon={<Caption>{t('group_life.hero.caption')}</Caption>}
          perks={heroPerks}
        />

        <Statistics
          title={t('group_life.statistics.title')}
          description={t('group_life.statistics.description')}
          items={numberRange(3).map((i) => ({
            title: t(`group_life.statistics.item_${i}.title`),
            description: <Trans t={t} i18nKey={`group_life.statistics.item_${i}.desc`} />,
            icon: statisticIcons[i],
          }))}
        />

        <GroupLifeLPSection section="section_coverage" imagePlacement="right" />
        <GroupLifeLPSection
          section="section_provider"
          logoAboveTitle={isGroupLifeAvailable() ? <AmalgamatedAgency /> : undefined}
        />
        <GroupLifeLPSection section="section_online" imagePlacement="right" />
        <GroupLifeLPSection section="section_estate_plan" />

        {isGroupLifeAvailable() && (
          <div>
            <Anchor id={'pricing-section'} />
            <Block>
              <PricingLPSection />
            </Block>
          </div>
        )}

        <div>
          <Anchor id={'faq-section'} />
          <FaqTwoColumns content={props.faq} />
        </div>

        <ToneContext.Provider value="dark">
          <Signup
            caption={t('group_life.section_signup.caption')}
            title={t('group_life.section_signup.title')}
            perks={signupPerks}
            variant="group_life"
          />
        </ToneContext.Provider>
      </Stack>
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  const mediaMentions = await getContentfulMediaMentions('global')

  return {
    props: {
      ...(await getIntlProps(ctx, ['group_life', 'pricing_plan'])),
      ...(await getContentfulImages()),
      faq: await getContentfulFAQ('group-life'),
      mediaMentions,
    },
    revalidate: REVALIDATE_SECS,
  }
}
