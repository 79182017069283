import { Carousel } from 'components/carousel/Carousel'
import { Spacer } from 'components/spacer/Spacer'
import { Text, Title_h4_default } from 'components/Typography'
import { useWindowSize } from 'utils/hooks'
import { StatisticItem } from './StatisticItem'
import {
  MobileStatistic,
  SDescriptionContainer,
  StatisticsWrapper,
  STitle,
  STitleContainer,
} from './Statistics.styled'
import { IStatisticsProps } from './types'

function MobileStatisticSection({ items }: { items?: JSX.Element[] }) {
  return <MobileStatistic>{items && <Carousel items={items} />}</MobileStatistic>
}

export function Statistics({ items, description, title, tone }: IStatisticsProps) {
  const { isDesktop } = useWindowSize({ isDesktop: true })

  const _items = items.map((s, i) => <StatisticItem key={i} {...s} tone={tone} />)

  return (
    <div>
      <STitleContainer>
        <STitle variant={Title_h4_default}>{title}</STitle>
      </STitleContainer>

      {!!description && (
        <SDescriptionContainer>
          <Text>{description}</Text>
        </SDescriptionContainer>
      )}

      <Spacer size={[2, 3]} />

      {isDesktop ? (
        <StatisticsWrapper space="0rem">{_items}</StatisticsWrapper>
      ) : (
        <MobileStatisticSection items={_items} />
      )}
    </div>
  )
}
