import { DescribedPerk } from 'components/groupLifeSection/DescribedPerk'
import { useTranslation } from 'next-i18next'

export const IncludesEstatePlan = () => {
  const { t } = useTranslation('group_life')

  return (
    <DescribedPerk
      title={t('group_life.pricing.includes_estate_plan.caption')}
      description={t('group_life.pricing.includes_estate_plan.text')}
    />
  )
}
