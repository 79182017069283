import { ContentfulMediaMentions } from 'api/contentful/getContentfulMediaMention'
import { ContentfulVideoSlug } from 'api/contentful/slugs/video'
import { ContentfulImageProps } from 'components/Image'
import { LandingPageHeroTitle } from 'components/landingPageHero/LandingPageHeroTitle'
import { LandingPageTextImageButton } from 'components/landingPageTextImageSection/LandingPageTextImageButton'
import { LandingPageTextImageMediaMentions } from 'components/landingPageTextImageSection/LandingPageTextImageMediaMentions'
import { LandingPageTextImagePerks } from 'components/landingPageTextImageSection/LandingPageTextImagePerks'
import { LandingPageTextImageSection } from 'components/landingPageTextImageSection/LandingPageTextImageSection'
import { ReactElement, ReactNode } from 'react'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { colors } from 'utils/colors'

export const HeroWithPerks = (props: {
  bgColor?: 'blue' | 'gray'
  mentions?: ContentfulMediaMentions
  icon?: ReactElement
  buttonText: string
  mentionsCaption: string
  title: string
  image?: ContentfulImageProps
  imageOverflow?: boolean
  perks: ReactNode[]
  buttonHref?: string
  videoButton?: ContentfulVideoSlug
}) => {
  const { isLogged } = useAuth()

  const bgColor = props.bgColor === 'gray' ? colors.gray[50] : colors.blue[75]
  const buttonTone = props?.bgColor && props.bgColor === 'gray' ? 'dark' : 'standard'

  const ctaHref = props.buttonHref ?? (isLogged ? '/me/dashboard' : '/signup')

  return (
    <LandingPageTextImageSection
      image={props.image}
      backgroundColor={bgColor}
      title={<LandingPageHeroTitle title={props.title} />}
      titlePlacement="above"
      icon={props.icon}
      imageOverflow={props.imageOverflow}
      fillMobileBackground
      videoButton={props.videoButton}
    >
      <LandingPageTextImagePerks perks={props.perks} />
      <LandingPageTextImageButton className="mobile-only" href={ctaHref} tone={buttonTone}>
        {props.buttonText}
      </LandingPageTextImageButton>
      <LandingPageTextImageMediaMentions
        title={props.mentionsCaption}
        mentions={props.mentions}
        responsiveItemCounts={[5, 5, 5]}
      />
      <LandingPageTextImageButton
        className="desktop-only"
        href={ctaHref}
        size={'large'}
        tone={buttonTone}
      >
        {props.buttonText}
      </LandingPageTextImageButton>
    </LandingPageTextImageSection>
  )
}
