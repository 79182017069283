import styled from '@emotion/styled'
import { Caption } from 'components/Typography'
import { FC } from 'react'

export const TopHighlight: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <SPositioner>
      <SLine />
      <SPill>
        <Caption css={{ color: 'white' }}>{children}</Caption>
      </SPill>
    </SPositioner>
  )
}

const SPositioner = styled.div({
  position: 'absolute',
  bottom: '100%',
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
})

const SLine = styled.div({
  backgroundColor: '#FFE6D4',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  height: '0.5rem',
})

const SPill = styled.div({
  backgroundColor: '#FFA360',
  zIndex: 2,
  borderRadius: '100vw',
  padding: '.125rem .75rem',
  transform: 'translateY(0.625rem)',
})
