import { css } from '@emotion/react'

export const TwoLineButtonContent = (props: {
  line1?: string
  line2?: string
  lines?: [string, string]
}) => {
  const line1 = props.line1 ?? props.lines?.[0]
  const line2 = props.line2 ?? props.lines?.[1]
  return (
    <>
      <span>{line1}</span>
      {line2 && (
        <>
          <br />
          <span
            css={css`
              font-weight: normal;
              font-size: 0.8125em;
            `}
          >
            {line2}
          </span>
        </>
      )}
    </>
  )
}
