import styled from '@emotion/styled'
import 'react-alice-carousel/lib/alice-carousel.css'

export const SWrapper = styled.div`
  & .alice-carousel__dots {
    margin: 0;
  }
`

export const SDot = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: ${({ isActive }) => (isActive ? 'var(--c-blue500)' : 'var(--c-blue100)')};
  :hover {
    cursor: pointer;
  }
`
