import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { BREAKPOINTS } from 'utils/styled'

const SMentionItemCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  width: 4rem;
  @media ${BREAKPOINTS.MD.max} {
    width: 4.5rem;
  }
  @media ${BREAKPOINTS.SM.max} {
    width: 4.5rem;
  }
`
export const SMentionItemLink = styled.a`
  text-decoration: none;
  ${SMentionItemCss};

  &:visited {
    color: inherit;
  }

  &:hover p {
    text-decoration: underline;
  }
`
export const SMentionItem = styled.div`
  ${SMentionItemCss}
`
export const SLogoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
