import { PricingSection } from 'components/groupLifeSection/PricingSection'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import { Caption, Text, Title, Title_h4_default } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'

export const PricingLPSection: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation('group_life')

  return (
    <Stack accurateSpacers gap="1.5rem" css={{ alignItems: 'center' }}>
      <Caption
        css={BREAKPOINTS.MD.css({
          alignSelf: 'start',
        })}
      >
        {t('group_life.section_pricing.caption')}
      </Caption>
      <Title variant={Title_h4_default}>{t('group_life.section_pricing.title')}</Title>
      <Text>{t('group_life.section_pricing.description')}</Text>

      <Spacer size={[1.5, pxToRemUnit(72)]} />

      <PricingSection />
    </Stack>
  )
}
