import { usePromotions } from 'api/goodtrust/promo'
import { useSubscriptionMap } from 'api/goodtrust/subscription'
import { PlanType } from 'logic/subscription/plan/types'
import { planSpecs } from 'logic/subscription/plan/spec'
import { isGroupLifePlanType } from 'logic/subscription/plan/type/parse'
import { useMemo } from 'react'
import { useRunOnceReady } from 'utils/hooks'
import { chooseDiscount } from 'utils/promo/chooseDiscount'

export function useBestPlanDiscount(
  planType: PlanType | undefined,
  onFirst?: (discount: NonNullable<ReturnType<typeof chooseDiscount>>) => void
) {
  const promoSwr = usePromotions()
  const subSwr = useSubscriptionMap()

  const promotions = promoSwr.data?.json
  const subItem = planType && subSwr.data?.json?.subscriptions?.[planType]?.DEFAULT
  const price = subItem?.price

  const planSpec = planType && planSpecs[planType]
  const discountType = planSpec?.discountType

  const discount = useMemo(() => {
    if (!promotions || price == null || !discountType) return undefined
    if (planType && isGroupLifePlanType(planType)) return undefined

    return chooseDiscount(promotions, {
      discountType,
      price,
    })
  }, [promotions, price, discountType, planType])

  useRunOnceReady(!!discount, () => discount && onFirst?.(discount))

  return discount
}
