import { PromoDiscountType } from 'api/goodtrust/promo'
import { describePromotion } from 'logic/promo/describe'
import { describePromoOffer } from 'logic/promo/describePromoOffer'
import { isNonNullable, numberComparator } from 'utils/general'
import { ApiType } from 'utils/types'

export function chooseDiscount(
  promotions: ApiType['ClaimedListResponse'],
  params: {
    price: number
    discountType: PromoDiscountType | undefined
  }
) {
  const redeemablePromotions = (promotions?.promo_codes ?? [])
    .map((promo) => {
      const promotionDescription = describePromotion(promo)
      const discount = promo.claimed_offers?.find(
        (offer) =>
          describePromoOffer(offer).asDiscount() &&
          promotionDescription.canRedeemDiscountOffer(offer, params.discountType)
      )

      if (!discount) return null

      const discountedPrice = describePromoOffer(discount).getDiscountedPrice(params.price) ?? 0
      return { discount, promo, discountedPrice }
    })
    .filter(isNonNullable)

  const sortedByPrice = redeemablePromotions.sort(
    numberComparator((promo) => promo.discountedPrice)
  )

  return sortedByPrice[0]
}
