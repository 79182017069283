import { Icon } from 'components/icon'
import { Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { FC } from 'react'
import { colors } from 'utils/colors'
import { pxToRemUnit } from 'utils/styled'

export const DescribedPerk: FC<{ title: string; description: string }> = ({
  title,
  description,
}) => {
  return (
    <Stack gap="0.25rem">
      <Stack gap={pxToRemUnit(10)} orientation="horizontal" css={{ alignItems: 'center' }}>
        <Icon name="Check" color={colors.blue[500]} size={'1rem'}></Icon>
        <Text variant={'bodyLargeBold'} color="black">
          {title}
        </Text>
      </Stack>

      <Text variant={'bodyLarge'}>{description}</Text>
    </Stack>
  )
}
