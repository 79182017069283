import styled from '@emotion/styled'
import { Title, Text } from 'components/Typography'
import { HStack, Stack } from 'components/Stack'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import { minSpace } from 'components/Block'

export const STitleContainer = styled.div`
  @media ${BREAKPOINTS.MD.max} {
    margin: 0 1rem;
  }
`

export const STitle = styled(Title)`
  text-align: center;
`

export const SDescriptionContainer = styled.div`
  text-align: center;
  margin-top: 1rem;

  p {
    margin: 0;
  }

  @media ${BREAKPOINTS.MD.max} {
    margin-left: ${minSpace};
    margin-right: ${minSpace};
  }
`

export const IconRoundBg = styled.div`
  flex-shrink: 0;
  width: ${pxToRemUnit(44)};
  height: ${pxToRemUnit(44)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatisticsWrapper = styled(HStack)`
  justify-content: center;
  height: ${pxToRemUnit(148)};
  margin: 0 1rem;
  > div:not(:last-child) {
    padding-right: 2rem;
    border-right: 1px solid #e7e8e9;
  }
  > div:not(:first-child) {
    padding-left: 2rem;
  }
`

export const SingleStatistic = styled(Stack)`
  height: 100%;
  align-items: center;

  @media ${BREAKPOINTS.MD.max} {
    text-align: center;
    margin-bottom: 1.5rem;
  }
`
export const StatisticsTextWrapper = styled(Stack)`
  max-width: ${pxToRemUnit(268)};

  @media ${BREAKPOINTS.MD.max} {
    max-width: unset;
  }
`

export const StatisticTitle = styled(Text)`
  font-size: 1.375rem;
  line-height: 200%;
`

export const MobileStatistic = styled.div`
  padding: 0 1.5rem;
  justify-content: center;
`
