import { Icon } from 'components/icon'
import { Text } from 'components/Typography'
import { colors } from 'utils/colors'
import {
  IconRoundBg,
  SingleStatistic,
  StatisticsTextWrapper,
  StatisticTitle,
} from './Statistics.styled'
import { StatisticItemProps } from './types'

export function StatisticItem(props: StatisticItemProps) {
  return (
    <SingleStatistic space="1rem" orientation={['vertical', 'horizontal']}>
      <IconRoundBg
        css={{
          backgroundColor: props.tone === 'standard' ? colors.blue[100] : colors.gray.greenishPale,
        }}
      >
        <Icon
          name={props.icon}
          color={props.tone === 'standard' ? colors.blue[500] : colors.black}
        />
      </IconRoundBg>
      <StatisticsTextWrapper>
        <StatisticTitle variant="bodyLargeBold" color={colors.black}>
          {props.title}
        </StatisticTitle>
        <Text>{props.description}</Text>
      </StatisticsTextWrapper>
    </SingleStatistic>
  )
}
