import { CobrandedPartnerLogo } from 'components/cobranding/logo/CobrandedPartnerLogo'
import { layoutContext } from 'components/layout/context'
import { HStack } from 'components/Stack'
import Link from 'next/link'
import { ReactNode, useContext } from 'react'
import { LogoOfTheDay } from 'utils/cobranding/LogoOfTheDay'
import { useCobrandedOffer } from 'utils/cobranding/useCobrandedOffer'
import {
  SDivider,
  SHeaderLandingPage,
  SLogoWrapper,
  SRightSideWrapper,
} from './TopNavigationLandingPage.styled'

export function BlankTopNavigation(props: {
  children?: ReactNode
  noSpacingBelow?: boolean
  logoUrl?: string
}) {
  const { scrolled } = useContext(layoutContext)

  const partner = useCobrandedOffer().navigation.partner
  return (
    <SHeaderLandingPage showBorder={scrolled} noSpacingBelow={props.noSpacingBelow}>
      <Link href={props.logoUrl ?? '/'} passHref legacyBehavior>
        {!partner ? (
          <SLogoWrapper>
            <LogoOfTheDay />
          </SLogoWrapper>
        ) : (
          <a>
            <HStack space="1rem">
              <SLogoWrapper>
                <LogoOfTheDay />
              </SLogoWrapper>
              {partner && (
                <>
                  <SDivider />
                  <CobrandedPartnerLogo logoUrl={partner.partnerImageUrl} />
                </>
              )}
            </HStack>
          </a>
        )}
      </Link>
      <SRightSideWrapper>{props.children}</SRightSideWrapper>
    </SHeaderLandingPage>
  )
}
