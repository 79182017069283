import { Button } from 'components/button/Button'
import { BlankTopNavigation } from 'components/header/topNavigationLandingPage/BlankTopNavigation'
import { Icon } from 'components/icon'
import { useMobileNavigationModal } from 'components/navigation/MobileNavigationModal'
import { Navigation } from 'components/navigation/Navigation'
import { PathProps } from 'components/navigation/Path'
import { isGroupLifeAvailable } from 'logic/subscription/plan/groupLife/isGroupLifeAvailable'
import { useTranslation } from 'next-i18next'
import { isNonNullable } from 'utils/general'

export function GroupLifeNavigation() {
  const { t } = useTranslation('group_life')

  const navigationContent: PathProps[] = [
    { name: t('group_life.navigation.about_us') as string, url: '/goodtrust-life/about-us' },
    { name: t('group_life.navigation.faq') as string, url: '/goodtrust-life/#faq-section' },
    isGroupLifeAvailable()
      ? {
          name: t('group_life.navigation.pricing') as string,
          url: '/goodtrust-life/#pricing-section',
        }
      : undefined,
    {
      name: t('group_life.navigation.get_started') as string,
      url: '/goodtrust-life/signup',
      isEncouragedAction: true,
    },
  ].filter(isNonNullable)

  const [mobileModal, isMobileModalOpen, openMobileModal, closeMobileModal] =
    useMobileNavigationModal({
      paths: navigationContent,
    })
  return (
    <BlankTopNavigation noSpacingBelow logoUrl="/goodtrust-life">
      <>
        {mobileModal}

        <Navigation className="desktop-only" paths={navigationContent} />
        <Button
          type="button"
          variant="text"
          tone="gray"
          className="mobile-only"
          onClick={isMobileModalOpen ? closeMobileModal : openMobileModal}
        >
          <Icon name={isMobileModalOpen ? 'Close' : 'MenuOpen'} size="1rem" />
        </Button>
      </>
    </BlankTopNavigation>
  )
}
