import { Path, PathProps } from 'components/navigation/Path'
import { useState } from 'react'
import { SNavigation } from './Navigation.styled'

export function Navigation({ className, paths }: { className?: string; paths?: PathProps[] }) {
  const [openPathIndex, setOpenPathIndex] = useState<number | null>(null)
  return (
    <SNavigation className={className}>
      {paths?.map((path, index) => {
        const open = openPathIndex === index
        const isSiblingOpen = openPathIndex != null && !open
        return (
          <Path
            {...path}
            key={`${path.url}_${index}`}
            isSiblingOpen={isSiblingOpen}
            open={open}
            onOpen={(open) => {
              setOpenPathIndex((openPathIndex) => {
                if (open) return index
                if (openPathIndex === index) return null
                return openPathIndex
              })
            }}
          />
        )
      })}
    </SNavigation>
  )
}
