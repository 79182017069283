import { postCancelDowngrade } from 'api/goodtrust/subscription'
import { getUserMe, useUserMe } from 'api/goodtrust/user'
import { PlanGroup } from 'logic/subscription/plan/types'
import { handleAndToastError, toastSuccess } from 'components/Toast'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import { fireEvent } from 'utils/analytics'
import { MessageException } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { getReadableString } from 'utils/general'
import { useLoadingCallback } from 'utils/hooks'

export function useCancelDowngrade() {
  const { mutate } = useUserMe()
  const [cancelDowngrade, isCancelingDowngrade] = useLoadingCallback(
    async (planGroup: PlanGroup) => {
      try {
        const userMe = await getUserMe().then(unwrapResponse.body)
        const subscription = describeMyPlans(userMe).forPlanGroup(planGroup).getSubscription()

        if (!subscription?.uuid)
          throw new MessageException('no_subs_type', {
            plan_group: getReadableString(planGroup),
          })

        await postCancelDowngrade(subscription.uuid).then(unwrapResponse)

        fireEvent('subscription_reactivate')
        toastSuccess('plan_downgrade_cancelled')
        await mutate()
      } catch (err) {
        handleAndToastError(err, 'failed_to_cancel_downgrade')
      }
    }
  )

  return { cancelDowngrade, isCancelingDowngrade }
}
