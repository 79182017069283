import { css } from '@emotion/react'

const navigationOverflowBufferSize = 100

/**
 * When `id` is used directly on the element, it will be overlapped with overflowing navigation bar.
 */
export function Anchor(props: { id: string }) {
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <div
        id={props.id}
        css={css`
          position: absolute;
          bottom: ${navigationOverflowBufferSize}px;
        `}
      />
    </div>
  )
}
